<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="tag" v-show="listType == 'card'">
      <template v-if="timeType == 1">
        <span class="gray" v-if="currentTime < dataSource.startTime">
          {{ $t("Pub_Tab_NotStart")
          }}<!-- 未开始 -->
        </span>
        <span class="blue" v-else>
          {{ $t("XB_UnderWay")
          }}<!-- 进行中 -->
        </span>
      </template>
      <template v-else>
        <span class="green" v-if="dataSource.complete == 1">
          {{ $t("CM_Completed")
          }}<!-- 已完成 -->
        </span>
        <span class="red" v-else-if="dataSource.complete == 2">
          {{ $t("CM_NotCompleted")
          }}<!-- 未完成 -->
        </span>
        <span class="orange" v-else-if="dataSource.complete == 3">
          {{ $t("CM_NoTakePartIn")
          }}<!-- 未参与 -->
        </span>
      </template>
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="detail">
      <h5>
        <template v-if="listType == 'list'">
          <template v-if="timeType == 1">
            <span
              class="status bg-gray"
              v-if="currentTime < dataSource.startTime"
            >
              {{ $t("Pub_Tab_NotStart")
              }}<!-- 未开始 -->
            </span>
            <span class="status bg-blue" v-else>
              {{ $t("XB_UnderWay")
              }}<!-- 进行中 -->
            </span>
          </template>
          <template v-else>
            <span class="status bg-green" v-if="dataSource.complete == 1">
              {{ $t("CM_Completed")
              }}<!-- 已完成 -->
            </span>
            <span class="status bg-red" v-else-if="dataSource.complete == 2">
              {{ $t("CM_NotCompleted")
              }}<!-- 未完成 -->
            </span>
            <span class="status bg-orange" v-else-if="dataSource.complete == 3">
              {{ $t("CM_NoTakePartIn")
              }}<!-- 未参与 -->
            </span>
          </template>
        </template>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.taskName }}
            </div>
          </template>
          {{ dataSource.taskName }}
        </a-popover>
      </h5>
      <div class="condition" v-show="listType == 'list'">
        <span class="label">{{ $t("CM_CCondition") }}：</span>
        <div style="flex: 1;">
          <!-- 完成条件 -->
          <div class="checkbox" v-if="1 & dataSource.completeCcondition">
            <a-checkbox :checked="(1 & dataSource.completeStatus) > 0">
              {{ $t("Lab_LessonDetail_T_SignIn")
              }}<!-- 签到 -->
            </a-checkbox>
          </div>
          <div class="checkbox" v-if="2 & dataSource.completeCcondition">
            <a-checkbox :checked="(2 & dataSource.completeStatus) > 0">
              {{ $t("Lab_LessonDetail_T_SignOut")
              }}<!-- 签退 -->
            </a-checkbox>
          </div>
          <div class="checkbox" v-if="4 & dataSource.completeCcondition">
            <a-checkbox :checked="(4 & dataSource.completeStatus) > 0">
              {{ $t("CM_evaluation_course")
              }}<!-- 评价课程 -->
            </a-checkbox>
          </div>
          <div class="checkbox" v-if="8 & dataSource.completeCcondition">
            <a-checkbox :checked="(8 & dataSource.completeStatus) > 0">
              {{ $t("CM_evaluation_lecturer")
              }}<!-- 评价讲师 -->
            </a-checkbox>
          </div>
          <div class="checkbox" v-if="16 & dataSource.completeCcondition">
            <a-checkbox :checked="(16 & dataSource.completeStatus) > 0">
              {{ $t("CM_leaders_evaluate_students") }}
              <!-- 领导评价 -->
            </a-checkbox>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 0">
        <span v-show="listType == 'list'">{{ $t("face.face_time") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/time.png"
          alt="time"
        /><span class="fs12"
          >{{ dateFormat(dataSource.startTime) }} ～
          {{ dateFormat(dataSource.endTime) }}</span
        >
        <!-- 面授时间 -->
      </div>
      <div class="row" v-show="listType == 'list'">
        {{ $t("XB_Lecturer") }}：<template
          v-for="(lecturer, lecturerIndex) in dataSource.trainLecturers || []"
          :key="lecturerIndex"
        >
          <OpenData
            type="userName"
            :openid="lecturer.lecturerName"
            v-if="lecturer.lecturerType == 1"
          /><span v-else>{{ lecturer.lecturerName }}</span
          >&nbsp;
        </template>
        <!-- 主讲老师 -->
      </div>
      <div class="row">
        <span v-show="listType == 'list'">{{ $t("face.address") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/addr.png"
          alt="addr"
        />{{ dataSource.address }}
        <!-- 培训地址 -->
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "FaceCard",
  components: {
    OpenData,
  },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
    timeType: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.newWindow) {
        window.open(
          `/train/detail?ddtab=true&id=${props.dataSource.taskId}&did=${props.dataSource.detailId}`
        );
      } else {
        router.push({
          path: "/train/detail",
          query: {
            id: props.dataSource.taskId,
            did: props.dataSource.detailId,
          },
        });
      }
    };

    return {
      dateFormat,
      currentTime: Date.parse(new Date()) / 1000,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
